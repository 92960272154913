import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MemberRole } from '@index/interfaces/member-role';
import { DBUtil } from '@index/utils/db-utils';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberRolesService {
  afs = inject(AngularFirestore);

  baseRef(teamId: string) {
    return this.afs.collection(DBUtil.Team).doc(teamId).collection(DBUtil.MemberRoles);
  }

  create(teamId: string, value: MemberRole) {
    const id = this.afs.createId();
    return from(this.baseRef(teamId).doc(id).set(Object.assign({}, { id }, value)));
  }

  update(teamId: string, id: string, dataToUpdate: { [key: string]: any }) {
    return from(this.baseRef(teamId).doc(id).update(dataToUpdate));
  }

  delete(teamId: string, id: string) {
    return from(this.baseRef(teamId).doc(id).update({ archived: true }));
  }

  valueChanges(teamId: string) {
    return this.baseRef(teamId).valueChanges();
  }

  getByTeamId$(teamId: string): Observable<MemberRole[]> {
    return this.afs
      .collection(`${DBUtil.Team}/${teamId}/${DBUtil.MemberRoles}`, (ref) =>
        ref.where('archived', '==', false),
      )
      .get()
      .pipe(
        map((docs) => {
          if (docs.empty) return [];
          return docs.docs.map((doc) => {
            return {
              ...(doc.data() as MemberRole),
              id: doc.id,
            };
          });
        }),
      );
  }
}
